import { LinkProps } from '@mui/material/Link'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import { ContactFlow, ContactFlowStep } from './_ContactFlow'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'

export const ContactFlowLink: React.FC<
  {
    initial?: ContactFlowStep
    reportedPlayer?: UserResource
    isBlockedLayout?: boolean
    listItem?: boolean
  } & LinkProps
> = ({
  children,
  reportedPlayer,
  initial,
  isBlockedLayout,
  listItem,
  ...restOfLinkProps
}) => {
  const { cfgContactIframe } = useFeatures()

  return (
    <ModalTrigger
      modalProps={{
        isBlockedLayout,
        noPadding: !!cfgContactIframe,
        noFooter: !!cfgContactIframe
      }}
      activation={handleOpen =>
        listItem ? (
          <ListItemButton onClick={handleOpen} tabIndex={0}>
            <ListItemIcon>
              <RcIcon icon={['fal', 'messages-question']} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body0'>
                <RcTrans i18nKey='shared.get-help-button' />
              </Typography>
            </ListItemText>
          </ListItemButton>
        ) : (
          <Link onClick={handleOpen} tabIndex={0} {...restOfLinkProps}>
            {children ? (
              children
            ) : (
              <RcTrans i18nKey='contact:general.open-text' />
            )}
          </Link>
        )
      }
    >
      {({ handleClose }) =>
        cfgContactIframe ? (
          <iframe
            src={cfgContactIframe as string}
            title='contact-override'
            style={{ flexGrow: 1, width: '100%', minHeight: 500, border: 0 }}
          />
        ) : (
          <ContactFlow
            initial={initial}
            reportedPlayer={reportedPlayer}
            onComplete={handleClose}
          />
        )
      }
    </ModalTrigger>
  )
}
