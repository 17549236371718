import { lighten } from '@mui/material/styles'
import Color from 'color'

export const TEXT_PALETTE = {
  primary: {
    dark: '#F3F3F3',
    light: '#141414'
  },
  secondary: {
    dark: '#B2B2B2',
    light: lighten('#141414', 0.4)
  },
  disabled: {
    dark: '#8C8C8C',
    light: '#999999'
  }
}

export const BACKGROUND_PALETTE = {
  default: {
    dark: '#1e1c1c',
    light: '#FBFBFB'
  },
  paper: {
    dark: '#2b2b2b',
    light: '#ededed'
  },
  card: {
    dark: '#3A3A3A',
    light: '#F6F6F6'
  },
  dialog: {
    dark: Color('#454545').lighten(0.1).hex(),
    light: Color('#EBEBEB').darken(0.1).hex()
  }
}

export const calcElevationString = (
  mode: 'dark' | 'light',
  elevation: number[]
) => {
  const ColorMix = mode === 'dark' ? Color('#000000') : Color('#FFFFFF')
  if (!elevation[1]) elevation[1] = elevation[0]
  const gradiantColors = elevation.map(e =>
    ColorMix.fade(0.1 + e * 0.05)
      .rgb()
      .string()
  )
  return `linear-gradient(90deg, ${gradiantColors.join(', ')})`
}

export const elevateColor = (color: string, elevation: number) =>
  Color(color)
    .mix(Color(`#000`), 1 - (0.1 + elevation * 0.05))
    .rgb()
    .string()

export const shadowBlend = (color1: string, alpha1: number, alpha2: number) =>
  Color(color1).alpha(alpha1).mix(Color(`#000`), alpha2).rgb().string()
