'use client'

import Button from '@mui/material/Button'
import { ButtonProps } from '@mui/material/Button'
import React, { forwardRef, useState } from 'react'

export const RcButton = forwardRef(function RcButton(
  props: ButtonProps & { component?: React.ElementType },
  ref: React.Ref<HTMLButtonElement>
) {
  const [disabled, setDisabled] = useState(false)

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setDisabled(true)
    try {
      await props.onClick?.(event)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <Button
      {...props}
      ref={ref}
      onClick={handleClick}
      disabled={props.disabled || disabled}
    />
  )
})
