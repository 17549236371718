'use client'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { FallbackProps } from 'react-error-boundary'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { PageItem } from '../page/PageItem'
import { ContactFlowLink } from '@/flows/Site/ContactFlow/ContactFlowLink'

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const status = (error as any)?.status

  const included = [401, 403, 404]
  if (included.includes(status)) {
    return (
      <PageItem>
        <Box pt={45} pb={45}>
          <Typography variant='h1' textAlign='center'>
            {status}
          </Typography>
          <Typography variant='body0' textAlign='center' color='textSecondary'>
            Sorry, we cant let you do that, Dave.
          </Typography>
        </Box>
      </PageItem>
    )
  }

  return (
    <PageItem>
      <Stack direction='column' spacing={5}>
        <Typography variant='h1' textAlign='center'>
          Oof, we hit an error {status}
        </Typography>
        <Typography variant='subtitle2' color='text2' textAlign='center'>
          You can try reloading the page, but if this keeps up please be sure to
          include the info below when you <ContactFlowLink />.
        </Typography>
        <Card elevation={4}>
          <CardContent>
            <Typography variant='caption' color='textSecondary'>
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {error.stack}
              </pre>
            </Typography>
          </CardContent>
        </Card>
      </Stack>
    </PageItem>
  )
}
